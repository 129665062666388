import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import { useLogoutAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
export default function AppBarView() {
  const { logout } = useLogoutAuth();
  const navigate = useNavigate();
  let { path = '/care' } = useParams();

  function logOut() {
    logout();
  }
  const handleOpenUserMenu = () => { navigate('/account');};
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNavgate = (path) => {
    navigate(path);
  };
  return (
    <Box
      sx={{
        borderBottom: "solid 1px lightgrey",
        display: "flex",
        height: "100%",
        px: 2,
        alignItems: "center",
        justifyContent: "flex-start",
        cursor: "pointer",
      }}
    >
      <Typography variant="title" fontWeight={500} color={"text.secondary"}>
        UH
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          px: 2,
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Box
          variant="title"
          
          component="div"
          onClick={()=>handleNavgate('/care')}
          sx={{
            borderBottom: `solid ${path==='/care'?'1px':'0px'} green`,
            display: "flex",
            alignItems: "center",
            width: "100px",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <Typography
            variant="caption"
            fontWeight={500}
            color={"text.secondary"}
          >
            CARE
          </Typography>
        </Box>
        <Box
          variant="title"
          component="div"
          onClick={()=>handleNavgate('/vitals')}
          sx={{
            borderBottom: `solid ${path==='/vitals'?'1px':'0px'} green`,
            display: "none",
            alignItems: "center",
            width: "100px",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <Typography
            variant="caption"
            fontWeight={500}
            color={"text.secondary"}
          >
            Vitals
          </Typography>
        </Box>
      </Box>
      <Box>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleOpenUserMenu}>My account</MenuItem>
          <MenuItem onClick={logOut}>Log out</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
}
